(function() {
	
	const _ = require('core/src/utils/legacy');
	const $ = require('jquery');	

	/*
		Expects a structure like this
			<div> <-- enable on this element
 				<input name="input" id="some-id" value="some value">
				<select>
					<option value="1">option 1</option>
					<option value="2">option 2</option>
					.....
				</select>
			</div>
	*/

	var combobox = function() {
		var $this = $(this);

		if ($this.length > 1) {
			$this.each(function() {
				typeahead.call(this);
			});

			return;
		}

		if ($this.attr('ia-activated') == 'true') {
			return;
		}

		$this.attr('ia-activated', 'true');
		
		var visibleInput = $this.find('input');
		var lastValue = visibleInput.val();
		var select = $this.find('select');

		var filterOptions = function(text) {
			text = text.toLowerCase();
			select.find('option').each(function() {
				var option = $(this);

				if (option.html().toLowerCase().indexOf(text) === -1) {
					option.hide();
					return;
				}

				option.show();
			});
		}

		var showSelect = function() {
			select.show();
			$(document).one('click', function() {
				select.hide();
			});
		}

		var updateInputValueFromSelect = function() {
			valueInput.val(select.val());
			visibleInput.val(select.find('option:selected').html());
		};

		var getVisibleValue = function(value) {
			var option = select
					.find('option')
					.filter(
						function() {
							return $(this).val() == value;
						}
					)
					.first();

			if (! option.length) {
				return value;
			}

			return option.html();

		}

		var getOptionValue = function(string) {
			var option = select
					.find('option')
					.filter(
						function() {
							return $(this).html().toLowerCase() == string.toLowerCase();
						}
					)
					.first();

			if (! option.length) {
				return string;
			}

			return option.val();
		}



		if (! select.length) {
			return;
		}

		var valueInput = $('<input type="hidden">')
				.attr('name', visibleInput.attr('name'))
				.attr('id', visibleInput.attr('id'))
				.val(visibleInput.val())
				.insertAfter(visibleInput);

		visibleInput
				.removeAttr('id')
				.removeAttr('name');

		visibleInput.val(getVisibleValue(visibleInput.val()));
		if (visibleInput.val()) {
			filterOptions(visibleInput.val());
		}

		select.css({
			display: "block",
			position: 'absolute',
			'min-width': visibleInput.css('width'),
			'z-index': 500
		})

		select.parent().css({
			position: 'relative'
		})

		select.hide();

		visibleInput.on('keyup', function(ev) {
			// on input change
			if (visibleInput.val() !== lastValue) {
				lastValue = visibleInput.val();
				valueInput.val(visibleInput.val());
				filterOptions(lastValue);
				showSelect();
				return;
			}

			// on Down
			if (ev.keyCode === 40) {
				select.find('option').first().attr('selected', 'selected');
				showSelect();
				select.focus().trigger('change');
			}

			// on Esc
			if (ev.keyCode === 27) {
				select.hide();
			}
		});

		visibleInput.on('change', function() {
			filterOptions(visibleInput.val());
			valueInput.val(getOptionValue(visibleInput.val()));
		})

		visibleInput.dblclick(function(ev) {
			showSelect();
		});

		visibleInput.on('search', function() {
			lastValue = visibleInput.val();
			valueInput.val(visibleInput.val());
			filterOptions(lastValue);
		});

		select.on('click change', updateInputValueFromSelect);


		select.on('click', function() {
			select.hide();
			visibleInput.focus();
		});

		select.on('keydown', function(ev) {
			// prevent form submit when selecting option with enter
			if (ev.keyCode === 13) {
				ev.preventDefault();
			}
		})

		select.on('keyup', function(ev) {
			//on Enter
			if (ev.keyCode === 13) {
				updateInputValueFromSelect();
				select.hide();
				visibleInput.focus();
			}

			//on Esc
			if (ev.keyCode === 27) {
				select.hide();
				visibleInput.focus();
				valueInput.val(lastValue);
				visibleInput.val(lastValue);
			}
		})
	};

	module.exports = combobox;
})();