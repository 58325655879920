const CodeMirror = require('codemirror/lib/codemirror');

require('codemirror/lib/codemirror.css');
require('codemirror/theme/neo.css');
require('codemirror/theme/neo.css');

require('codemirror/mode/cypher/cypher.js');
require('codemirror/mode/sparql/sparql');
require('codemirror/mode/javascript/javascript.js');
require('codemirror/mode/xml/xml.js');

require('codemirror/addon/mode/overlay.js');
require('codemirror/addon/display/placeholder.js');
require('codemirror/addon/wrap/hardwrap.js');
require('codemirror/addon/hint/css-hint.js');
require('codemirror/addon/hint/show-hint.js');

module.exports = CodeMirror;
