let scopedRule = (scope, {selectorText: selector = '', cssText: css, style: {cssText} = {}, styleSheet, cssRules, media}) =>
	styleSheet ? // @import rules
		scopedRules(scope, styleSheet.cssRules) :
		cssRules && media ?  // @media rules
			`@media ${Array.from(media).join(',\n')} {${scopedRules(scope, cssRules)}}` :
			!selector || selector.startsWith(':root') || selector.startsWith('body') ?
				css :
				`${selector.split(',').map(s => `${scope} ${s}`).join(', ')} {${cssText}}`;

let scopedRules = (scope, rules = []) => Array.from(rules, r => scopedRule(scope, r)).join('\n');

let autoScopeID = 0;

function scopeStyle(style, scope) {
	if(!scope) scope = 'scoped_' + autoScopeID++;

	let scoped = document.createElement('style');
	scoped.type = 'text/css';

	let parent = style.parentNode;
	parent.removeChild(style);
	document.head.appendChild(style);

	scoped.textContent = scopedRules('.'+scope, style.sheet.cssRules);

	document.head.removeChild(style);
	parent.appendChild(scoped);

	return scope;
}

module.exports = {
	scopeStyle
};
