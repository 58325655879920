(function() {

	const _ = require('core/src/utils/legacy');
	const $ = require('jquery');	


	/*
		Expects a structure like this:

		<div> <--- enable on this element
			<input 	type="search"
					name="list_of_demands"
			/>
			<div class="options-table"
				 style="height: 100px; width: 400px; overflow: hidden; background-color: white; border: 1px solid blue;"
			>
				<table style="width: 100%">
					<tr>
						<th>
							Name
						</th>
						<th>
							Version
						</th>
						<th>
							Gültig von
						</th>
						<th>
							Gültig bis
						</th>
					</tr>
				{{#data.demands}}
					<tr value="{{id}}"
						label="{{name}} {{version}}"
						option="true"
						tabindex="1"
					>
						<td>{{name}}</td>
						<td>{{version}}</td>
						<td>{{validFrom}}</td>
						<td>{{validTo}}</td>
					</tr>
				{{/data.demands}}
				</table>
			</div>
		</div>
	*/

	var tableTypeahead = function() {
		var $this = $(this);

		if ($this.length > 1) {
			$this.each(function() {
				typeahead.call(this);
			});

			return;
		}

		if ($this.attr('ia-activated') == 'true') {
			return;
		}

		$this.attr('ia-activated', 'true');
		
		var visibleInput = $this.find('input');
		var lastValue = visibleInput.val();
		var select = $this.find('.options-table').first();
		var lastSearch;

		var getOptionContent = function(option) {
			return option.textContent || option.innerText || '';
		}

		var filterOptions = _.debounce(
			function(text) {
				if (lastSearch === text) {
					return;
				}
				lastSearch = text;

				var eventData = _.extend(
						{},
						{
							inputName: valueInput.attr('name'),
							type: 'typeahead-search',
							searchedString: visibleInput.val(),
							selectId: select.attr('id'),

						},
						$this.data()
					);
				$this.trigger(
					'interactor-event',
					eventData
				);
			},
			500
		);

		var showSelect = function() {
			select.show();
			$(document).one('click', function() {
				setTimeout(
					function() {
						select.hide();
					},
					200
				);
			});
		}

		var getOptionElements = function(select) {
			return select.find('[option]');
		}

		var getVisibleOptionElements = function(select) {
			return select.find('[option]:visible');
		}

		var getSelectedOption = function(select) {
			return select.find('[option][selected]:visible').first();
		}

		var getOptionLabel = function(optionElement) {
			return $(optionElement).attr('label') || '';
		}

		var updateInputValueFromSelect = function() {
			valueInput.val(getSelectedOption(select).attr('value'));
			visibleInput.val(getSelectedOption(select).attr('label'));
		};

		var getVisibleValue = function(value) {
			var option = getOptionElements(select)
					.filter(
						function() {
							return $(this).attr('value') == value;
						}
					)
					.first();

			if (! option.length) {
				return value;
			}

			return getOptionLabel(option[0]);
		}

		var getOptionValue = function(string) {
			var stringLowered = string.toLowerCase();

			var option = getOptionElements(select)
					.filter(
						function() {
							return getOptionLabel(this).toLowerCase() == stringLowered;
						}
					)
					.first();

			if (! option.length) {
				return string;
			}

			return option.attr('value');
		}


		var selectFocus = function() {
			var visibleOptions = getVisibleOptionElements(select);
			var selectedOption = getSelectedOption(select);

			if (! selectedOption.length) {
				selectedOption = visibleOptions.first();
			}

			if (! selectedOption.length) {
				return;
			}

			selectedOption.focus().attr('selected', 'selected');
		}

		if (! select.length) {
			return;
		}

		var valueInput = $('<input type="hidden">')
				.attr('name', visibleInput.attr('name'))
				.attr('id', visibleInput.attr('id'))
				.val(visibleInput.val())
				.insertAfter(visibleInput);

		visibleInput
				.removeAttr('id')
				.removeAttr('name')
				.val(visibleInput.attr('label'));

		visibleInput.val(getVisibleValue(visibleInput.val()));
		if (visibleInput.val()) {
			filterOptions(visibleInput.val());
		}

		select.css({
			display: "block",
			position: 'absolute',
			'min-width': visibleInput.css('width'),
			'z-index': 500
		})

		select.attr('id',  _.uniqueId('interactor-typeahead-options-'));

		select.parent().css({
			position: 'relative'
		})

		select.hide();

		visibleInput.on('keyup', function(ev) {
			// on input change
			if (visibleInput.val() !== lastValue) {
				lastValue = visibleInput.val();
				valueInput.val(visibleInput.val());
				filterOptions(lastValue);
				showSelect();
				return;
			}

			// on Down
			if (ev.keyCode === 40) {
				showSelect();
				selectFocus();
			}

			// on Esc
			if (ev.keyCode === 27) {
				select.hide();
			}
		});

		visibleInput.dblclick(function(ev) {
			showSelect();
			if (_.isNil(lastSearch)) {
				filterOptions(visibleInput.val());
			}
		});

		visibleInput.change(function() {
			// It does not make sense to filter when the input is blurred
			// filterOptions(visibleInput.val());
			valueInput.val(getOptionValue(visibleInput.val()));
		});


		visibleInput.on('search', function() {
			lastValue = visibleInput.val();
			valueInput.val(visibleInput.val());
			filterOptions(lastValue);
		});


		select.on('click change', updateInputValueFromSelect);

		select.on('keydown', function(ev) {
			// prevent form submit when selecting option with enter
			if (ev.keyCode === 13) {
				ev.preventDefault();
			}
		})

		select.on('keyup', function(ev) {
			//on Enter
			if (ev.keyCode === 13) {
				updateInputValueFromSelect();
				select.hide();
				visibleInput.focus();
			}

			//on Esc
			if (ev.keyCode === 27) {
				select.hide();
				visibleInput.focus();
				valueInput.val(lastValue);
				visibleInput.val(lastValue);
			}

			//on Down
			if (ev.keyCode === 40) {
				var next = false;
				var selectedOption;

				getVisibleOptionElements(select).each(function() {
					if (next) {
						$(selectedOption).removeAttr('selected');
						$(this).attr('selected', 'selected').focus();
						select.trigger('change');
						return false;
					}

					if ($(this).attr('selected')) {
						next = true;
						selectedOption = this;
					};
				});
			}

			// on Up
			if (ev.keyCode === 38) {
				var done = false;
				var prevOption;
				getVisibleOptionElements(select).each(function() {
					var $this = $(this);
					if ($this.attr('selected')) {
						if (! prevOption) {
							return false;
						}

						$this.removeAttr('selected');
						return false;
					};

					prevOption = this;
				});

				if (prevOption) {
					$(prevOption).attr('selected', 'selected').focus();
					select.trigger('change');
				}


			}
		});

		select.on('click', '[option]', function() {
			getSelectedOption(select).removeAttr('selected');
			$(this).attr('selected', 'selected');
			select.trigger('change');
			visibleInput.focus();
		});
	};

	module.exports = tableTypeahead;

})();