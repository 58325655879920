function applyZoom(scale, el, pos) {
    //console.error({scale, el, origin});
    //el.style.transform = `translate(${origin.x}px, ${origin.y}px) scale(${scale})`
    let origin = {
        x: Math.abs(pos.x),//50,//el.clientWidth / Math.abs(pos.x),
        y: Math.abs(pos.y),//50//el.clientHeight / Math.abs(pos.y),
    }
    //console.error({origin, pos}, el.clientWidth, el.clientHeight)
    let p = ["webkit", "moz", "ms", "o"],
        //s = `translate(${origin.x}px, ${origin.y}px) scale(${scale})`,
        s = `scale(${scale})`,
        oString = (origin.x) + "% " + (origin.y) + "%";

    for (let i = 0; i < p.length; i++) {
        el.style[p[i] + "Transform"] = s;
        el.style[p[i] + "TransformOrigin"] = oString;
    }
    //console.error({s})
    el.style["transform"] = s;
    el.style["transformOrigin"] = oString;
}
function setZoom(zoom, scaleLimit, el, event) {

    el = el[0];
    //const matrixRegex = /matrix\((-?\d*\.?\d+),\s*0,\s*0,\s*(-?\d*\.?\d+),\s*(-?\d*\.?\d+),\s*(-?\d*\.?\d+)\)/,
    const matrixRegex = /matrix\((-?\d*\.?\d+),\s*0,\s*0,\s*(-?\d*\.?\d+),\s*-?\d*\.?\d+,\s*-?\d*\.?\d+\)/,
    matches = $(el).css('-webkit-transform').match(matrixRegex);
    let currentScale, pointX = 0, pointY = 0;
    if (!matches)
        currentScale = 1
    else {
        //pointY = matches.pop() * 1;
        //pointX = matches.pop() * 1;
        currentScale = matches.pop() * 1;

    }
    let newScale = (zoom > 0) ? (currentScale * zoom) : (currentScale / (-1 * zoom));
    /*const xs = (event.clientX - pointX) / currentScale,
        ys = (event.clientY - pointY) / currentScale;

    pointX = event.clientX - xs * newScale;
    pointY = event.clientY - ys * newScale;*/

    if ( zoom < 0 && newScale <= scaleLimit )
        return;
    if ( zoom > 0 && newScale >= scaleLimit )
        return;

    //applyZoom(newScale, el, {x: event.clientX, y: event.clientY});
    applyZoom(newScale, el, {x: 50, y: 50});
}
module.exports = function($) {
    $.fn.serializeObject = function()
    {
        var o = {};
        var a = this.serializeArray();
        $.each(a, function() {
            if (o[this.name] !== undefined) {
                if (!o[this.name].push) {
                    o[this.name] = [o[this.name]];
                }
                o[this.name].push(this.value || '');
            } else {
                o[this.name] = this.value || '';
            }
        });
        return o;
    };

    $.fn.zoomIn = function(speed, maxScale, e) {
        setZoom(speed || 1.2, maxScale || 4, this, e);
    }
    $.fn.zoomOut = function(speed, minScale, e) {
        setZoom((-1 * (speed || 1.2)), minScale || 0.3, this, e)
    }
    $.fn.zoomReset = function() {
        applyZoom(1, this[0], {x: 0, y: 0})
    }
    $.fn.zoomToFit = function(scale) {
        applyZoom(scale, this[0], {x: 0, y: 0})
    }
};
